import axios from "axios";
import { storage } from "../firebase/firebase";
import { getDownloadURL, ref, getBlob } from "firebase/storage";
import firebase from "firebase/app";
import { generateRandomString } from "../utils/converter";
import JSONFile from "../types";
import { User } from "firebase/auth";

const prefixes = {
  Readthrough: "vidRT",
  Proofread: "vidPR",
  Envision: "vidPT",
  Musical: "vidMT",
  Chat: "vidCH",
} as const;

type StudioType = keyof typeof prefixes;
const STUDIO_TYPES = Object.keys(prefixes) as StudioType[];

const isStudio = (studioType: StudioType) =>
  !!process.env[`REACT_APP_${studioType.toUpperCase()}_STUDIO`] ||
  window.location.hostname.includes(`${studioType.toLowerCase()}.`);

type StudioFlags = {
  [K in `is${StudioType}Studio`]: boolean;
};

const studioFlags = Object.fromEntries(
  STUDIO_TYPES.map((type) => [`is${type}Studio`, isStudio(type)])
) as StudioFlags;

// console.log("studioFlags", studioFlags);
export const {
  isEnvisionStudio,
  isMusicalStudio,
  isReadthroughStudio,
  isProofreadStudio,
  isChatStudio,
} = studioFlags;

// console.log("sutido flags", studioFlags);
export const isScreenplayIQ = !Object.values(studioFlags).some(Boolean);

export const isValidReportIdForCurrentStudio = (reportId: string): boolean => {
  if (isEnvisionStudio && !isReadthroughStudio) {
    return (
      reportId.startsWith(prefixes.Envision) ||
      (reportId.startsWith("vid") &&
        !reportId.startsWith(prefixes.Readthrough) &&
        !reportId.startsWith(prefixes.Proofread))
    );
  }
  const numStudioTypes = STUDIO_TYPES.filter(
    (type) => studioFlags[`is${type}Studio`]
  ).length;

  const isValidStudioPrefix = (type: StudioType) =>
    studioFlags[`is${type}Studio`] && reportId.startsWith(prefixes[type]);

  if (isScreenplayIQ) return !reportId.startsWith("vid");

  if (isEnvisionStudio && numStudioTypes > 1) {
    return STUDIO_TYPES.filter((type) => type !== "Envision").some(
      isValidStudioPrefix
    );
  }

  return STUDIO_TYPES.some(isValidStudioPrefix);
};

export const studioDescription =
  isScreenplayIQ || isEnvisionStudio || isReadthroughStudio
    ? "Visualize your story from a new perspective"
    : isProofreadStudio
    ? "Proofread your screenplays"
    : isMusicalStudio
    ? "Create and edit musicals"
    : "Visualize your story from a new perspective";

export const studioName = isScreenplayIQ
  ? "ScreenplayIQ"
  : isProofreadStudio
  ? "ScreenplayProof"
  : isReadthroughStudio
  ? "ReadThrough"
  : isMusicalStudio
  ? "Musical"
  : isEnvisionStudio
  ? "PitchTrailer"
  : isChatStudio
  ? "ScreenplayChat"
  : "ScreenplayIQ";

export const getNewReportId = () => {
  const prefix = STUDIO_TYPES.find((type) => studioFlags[`is${type}Studio`])
    ? prefixes[
        STUDIO_TYPES.find(
          (type) => studioFlags[`is${type}Studio`]
        ) as StudioType
      ]
    : "";

  const randomString = generateRandomString(30);
  return `${prefix}v1${randomString}`;
};

export const fetchReportData = async (
  reportId: string,
  authUser?: User
): Promise<any> => {
  const reportRef = ref(
    storage,
    `screenplayIQ/scripts/${reportId}/report/structuredData.json`
  );

  /* 
  let token = "";
  if (authUser) {
    token = await authUser.getIdToken();
  }

  // const doFetch = async () => {
  //   let options = {};
  //     const firebaseIdToken = await getFirebaseIdToken();
  //       options = {
  //         headers: { Authorization: "Firebase " + firebaseIdToken },
  //       };
  //   }
  //   return await fetch(url, options);
  // };

  const bucketName = reportRef.bucket;
  const url = `${
    process.env.REACT_APP_EMULATE
      ? "http://localhost:9199"
      : "https://firebasestorage.googleapis.com"
  }/v0/b/${bucketName}/o/${encodeURIComponent(reportRef.fullPath)}?alt=media`;

  const response = await axios.get(url, {
    headers: token ? { Authorization: `Firebase ${token}` } : undefined,
  });
  // const data: typia.IValidation<JSONFile> = typia.validateEquals<JSONFile>(
  //   response.data
  // );

  const data = response.data;
  return data;
 */

  async function getJsonFromBlobWithRetry(
    reportRef: any,
    retries: number = 0,
    delay: number = 1000
  ): Promise<any> {
    try {
      const blob = await getBlob(reportRef);

      const blobToString = () => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsText(blob);
        });
      };

      // @ts-ignore
      const stringData = JSON.parse(await blobToString());
      return stringData;
    } catch (error) {
      // @ts-ignore
      if (error.code == "storage/object-not-found") {
        // Treat it like an empty JSON object if the file doesn't exist yet
        return {};
      }
      if (retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return getJsonFromBlobWithRetry(reportRef, retries - 1, delay * 2);
      } else {
        throw error;
      }
    }
  }

  return await getJsonFromBlobWithRetry(reportRef);
};
