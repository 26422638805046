// Assuming ReportSchema, ReportProperties, and useReportRTDBFields are defined elsewhere
import ReportSchema from "../types";
import { AnalysisRun, ReportProperties } from "../context/ReportContext";
import { reportSections } from "../components/modals/RunAnalysisModal";

export default class StructuredReport {
  public reportId: string;
  public data: ReportSchema;
  public properties: ReportProperties;
  constructor(
    reportId: string,
    properties: ReportProperties,
    data: ReportSchema
  ) {
    this.reportId = reportId;
    this.data = data;
    this.properties = properties;
  }

  public get title() {
    return this.properties.title || "Untitled";
  }

  public get author() {
    return this.properties.author;
  }

  public get numCharacters() {
    return this.characters.length;
  }

  public get includedAnalyses() {
    const analysisList = this.properties.analysisList;
    return analysisList
      ? Object.keys(analysisList).reduce((acc, key) => {
          if ((analysisList[key] as AnalysisRun)?.included) {
            acc[key] = analysisList[key];
          }
          return acc;
        }, {} as Record<string, AnalysisRun>)
      : {};
  }

  public get completedAnalyses() {
    const analysisList = this.properties.analysisList;
    return analysisList
      ? Object.keys(analysisList).reduce((acc, key) => {
          if ((analysisList[key] as AnalysisRun)?.completedTimestamp) {
            acc[key] = analysisList[key];
          }
          return acc;
        }, {} as Record<string, AnalysisRun>)
      : {};
  }

  public get incompleteAnalyses() {
    const analysisList = this.properties.analysisList;
    return analysisList
      ? Object.keys(analysisList).reduce((acc, key) => {
          if (
            (analysisList[key] as AnalysisRun)?.included &&
            !analysisList[key]?.completedTimestamp
          ) {
            acc[key] = analysisList[key];
          }
          return acc;
        }, {} as Record<string, AnalysisRun>)
      : {};
  }

  public get analysesInProgress() {
    const analysisList = this.properties.analysisList;
    return analysisList
      ? Object.keys(analysisList).reduce((acc, key) => {
          if (
            (analysisList[key] as AnalysisRun)?.startedTimestamp &&
            !analysisList[key]?.completedTimestamp
          ) {
            acc[key] = analysisList[key];
          }
          return acc;
        }, {} as Record<string, AnalysisRun>)
      : {};
  }

  public get includedSections() {
    const list: Record<string, boolean> = {};
    for (const section of reportSections) {
      if (
        section.subsections.find(
          (subsection) => this.includedAnalyses[subsection.id]
        )
      ) {
        list[section.id as string] = true;
      }
    }
    return list;
  }

  public get completedSections() {
    const completedSections: Record<string, boolean> = {};
    for (const section of reportSections) {
      if (
        this.includedSections[section.id as string] &&
        section.subsections.every(
          (subsection) => !this.incompleteAnalyses[subsection.id]
        )
      ) {
        completedSections[section.id as string] = true;
      }
    }
    return completedSections;
  }

  public get SynopsisCategories() {
    return Object.entries(
      this.data.overview?.summary?.analysis?.synopsisInPieces || {}
    );
  }

  public get isOwner() {
    if (!this.properties.activeUserId || !this.properties.users) return false;
    return (
      this.properties.users[
        this.properties.activeUserId as keyof typeof this.properties.users
      ] === "owner"
    );
  }

  public get isEditable() {
    return this.isOwner || !!this.properties.settings?.publicEditable;
  }

  public get scriptType() {
    switch (this.properties.scriptType) {
      case "film":
        return "Film";
      case "tv_pilot":
        return "TV Pilot";
      case "short_film":
        return "Short Film";
      case "stageplay":
        return "Stage Play";
      case "musical":
        return "Stage Musical";
      case "book":
        return "Book";
      case "other":
      default:
        return "Story";
    }
  }

  public get scriptTypeLower() {
    switch (this.properties.scriptType) {
      case "film":
        return "film";
      case "tv_pilot":
        return "TV pilot";
      case "short_film":
        return "short film";
      case "stageplay":
        return "stage play";
      case "musical":
        return "stage musical";
      case "book":
        return "book";
      case "other":
      default:
        return "story";
    }
  }

  public get lineItems() {
    if (!this.data.productionDetails) return [];
    return Object.entries(this.data.productionDetails)
      .filter((lineItem) => Object.values(lineItem[1] || {}).length)
      .map((lineItem) => {
        const subItems = Object.entries(lineItem[1] || {}).map(
          ([subItemKey, subItem]) => {
            return {
              lineItem: subItem.label,
              qty: subItem.quantity || 1,
              low: subItem.budget?.low || 0,
              high: subItem.budget?.high || 0,
              estimates: subItem,
            };
          }
        );
        const totalLowCost = subItems.reduce((acc, item) => acc + item.low, 0);
        const totalHighCost = subItems.reduce(
          (acc, item) => acc + item.high,
          0
        );
        const totalQty = subItems.reduce((acc, item) => acc + item.qty, 0);
        const formattedLineItem = lineItem[0]
          .replace(/([A-Z])/g, " $1") // insert a space before all capital letters
          .trim() // remove leading and trailing spaces
          .replace(/^\w/, (c) => c.toUpperCase()) // capitalize the first letter
          .replace(/\b(With|For|At|As|And|Or|In)\b/g, (match) =>
            match.toLowerCase()
          ); // replace "And", "Or", and "In" with "and", "or", and "in"

        return {
          lineItem: formattedLineItem,
          qty: totalQty,
          low: totalLowCost,
          high: totalHighCost,
          subItems: subItems,
        };
      });
  }

  public get totalBudget() {
    const lineItems = this.lineItems;
    return [
      lineItems.reduce((acc, item) => acc + item.low, 0),
      lineItems.reduce((acc, item) => acc + item.high, 0),
    ];
  }

  public get schedule() {
    const realSchedule = this.data.schedule;
    const sceneDetails = this.data.sceneDetails;
    const productionDetails = this.data.productionDetails;
    const characterDetails = this.data.characterDetails;

    if (
      !realSchedule ||
      !sceneDetails ||
      !productionDetails ||
      !characterDetails
    )
      return [];

    const schedule = Object.entries(realSchedule).map((item, day) => {
      const scenes = item[1].scenes;
      const allSceneDetails = Object.entries(scenes)
        // .filter(([sceneId, scene]) => {
        //   const details = sceneDetails[sceneId];
        //   return details?.sceneAnalysis;
        // })
        .map(([sceneId, scene]) => {
          const details = sceneDetails[sceneId];
          const sceneDuration = scene.endTimeMs - scene.startTimeMs;
          return {
            sceneNumber: details.sceneSummary.header.sceneNumber || null,
            header: details.sceneSummary.header.text,
            description: details.sceneSummary.description,
            duration: sceneDuration,
            productionDetails: details.productionDetails,
            characterAnalysis: Object.keys(details.characterAnalysis || {}),
          };
        });

      const locations = allSceneDetails.reduce((acc, scene) => {
        const locations = Object.keys(
          scene.productionDetails?.locations || {}
        ).map((location) => productionDetails.locations?.[location]?.label);

        locations.forEach((location) => {
          if (location && !acc.includes(location)) acc.push(location);
        });

        return acc;
      }, [] as string[]);

      const cast = allSceneDetails.reduce((acc, scene) => {
        const characters = scene.characterAnalysis;
        characters.forEach((characterId) => {
          const details = characterDetails[characterId];
          if (!acc.includes(details.name)) acc.push(details.name);
        });
        return acc;
      }, [] as string[]);

      return {
        day: day + 1,
        locations: locations,
        cast: cast,
        scenes: allSceneDetails,
      };
    });
    return schedule;
  }

  public get productionDays() {
    return this.schedule.length;
  }

  public get hasLoadedInitialData() {
    return !!(this.data && this.properties && this.properties.title);
  }

  public get characters() {
    return Object.values(this.data.characterDetails || {})
      .filter((character) => {
        return !character.overview?.doNotRender && character.name;
      })
      .sort((a, b) => {
        if (a.overview?.role === b.overview?.role) {
          // if role is the same, sort by dialogue count
          if (
            b.overview?.archetypes?.story === "Protagonist" &&
            a.overview?.archetypes?.story !== "Protagonist"
          )
            return 1;
          return b.overview?.dialogueCount - a.overview?.dialogueCount;
        }
        if (a.overview?.role === "Main") return -1;
        if (b.overview?.role === "Main") return 1;
        if (a.overview?.role === "Supporting") return -1;
        if (b.overview?.role === "Supporting") return 1;
        if (a.overview?.role === "Minor") return -1;
        if (b.overview?.role === "Minor") return 1;
        return 0;
      });
  }

  public get isConnectedToWdDocument() {
    return !!this.properties.wdUrl;
  }
}

// StructuredReport class remains unchanged
